import { colors, fontSizes, Icon, mixin } from "@hubrise/react-components"
import styled from "styled-components"

import { Status } from "models/hubrise/types"
import { ColorScheme, orderColors } from "styles/common"

import { bodyHeight, headerHeight } from "../Styles"

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div<{ $status: Status; $colorScheme: ColorScheme }>`
  ${headerHeight};
  position: relative;

  ${mixin.centerElement};
  color: ${({ $colorScheme }) => ($colorScheme === "normal" ? colors.white : colors.textDarkest)};
  background-color: ${({ $status, $colorScheme }) => orderColors[$colorScheme].statusBackground[$status]};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

export const Title = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
`

export const OrderStatus = styled.div`
  font-size: ${fontSizes._12};
`

export const Price = styled.div`
  font-size: ${fontSizes._18};
`

export const Notification = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  opacity: 0;
  transition: opacity 0.2s linear 0.5s;
  ${({ $isVisible }) => $isVisible === true && `opacity: 1;`}
`

export const Body = styled.div`
  ${bodyHeight};
  display: flex;
`

export const IconHolder = styled.div<{ $colorScheme: ColorScheme }>`
  ${mixin.centerElement};
  margin-left: 0.75rem;
  width: 2.25rem;
  filter: ${({ $colorScheme }) => ($colorScheme === "dimmed" ? "grayscale(100%)" : "none")};
`

export const Info = styled.div`
  flex: 1;
  margin: 0 0.75rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
  overflow: hidden;
`

export const InfoLine = styled.div<{ $bold?: boolean }>`
  color: ${colors.textDarkest};
  ${mixin.noWrapEllipsis};
  font-weight: ${({ $bold }) => ($bold === true ? "500" : "normal")};
`

export const ServiceType = styled.div<{ $isLate: boolean }>`
  width: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-left: 1px solid ${colors.borderLight};
  color: ${({ $isLate }) => ($isLate === true ? orderColors.late : colors.textMedium)};
`

export const ServiceTypeIcon = styled(Icon).attrs({ size: 32 })``

export const Time = styled.div`
  font-weight: 500;
`
