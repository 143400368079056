import { breakpoints, colors, ModalStyle as Modal, sizes } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const Grid = styled(Modal.Body)`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.mobile}) {
    grid-template-columns: 30% 1fr;
  }

  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 25% 1fr;
  }
`

export const Tab = styled.div<{ $isActive: boolean }>`
  grid-column: 1 / 2;
  background-color: ${colors.backgroundLight};
  border-bottom: 1px solid ${colors.borderLightest};
  border-bottom-left-radius: ${sizes.borderRadius};
  cursor: pointer;
  font-weight: lighter;
  padding: 1rem;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${colors.textDarkest};
      background-color: ${colors.white};
      font-weight: normal;

      @media not (min-width: ${breakpoints.mobile}) {
        position: relative;

        &:after {
          border-left: 0.25rem solid transparent;
          border-right: 0.25rem solid transparent;
          border-top: 0.4rem solid ${colors.textDark};
          content: "";
          height: 0;
          position: absolute;
          right: 1.25rem;
          top: 1rem;
          width: 0;
        }
      }
    `}
`

export const Main = styled.div`
  grid-column: 1;
  padding: 1rem;
  background-color: ${colors.white};
  border-bottom-left-radius: ${sizes.borderRadius};
  border-bottom-right-radius: ${sizes.borderRadius};

  @media (min-width: ${breakpoints.mobile}) {
    grid-column: 2;
    grid-row: 1 / 5;
  }
`
