import * as React from "react"
import { useNavigate, useParams } from "react-router-dom"

import InventoryList from "components/InventoryList"
import { Section } from "components/InventoryList/types"
import Layout from "components/Layout"
import Loading from "components/Loading"
import { useConfig } from "contexts/ConfigContext"
import { useCatalogInventoryQuery, useCatalogQuery, useCatalogSummariesQuery } from "hooks/queries"
import useAppNavigation from "hooks/useAppNavigation"
import useTitle from "hooks/useTitle"
import useWebsocket from "hooks/useWebsocket"
import { Subscription } from "models/Subscription"
import { importCatalogInventory } from "models/hubrise/CatalogInventory"

interface InventoryProps {
  section: Section
}

const Inventory = ({ section }: InventoryProps): JSX.Element => {
  const navigate = useNavigate()
  const { inventoryLoaderPath, inventoryPath } = useAppNavigation()

  const { appInstanceId, pusherWebsocket } = useConfig()
  const { catalogId } = useParams<{ catalogId: string }>()

  useTitle(0)

  const { data: catalogSummaries, refetch: refetchCatalogSummaries } = useCatalogSummariesQuery(appInstanceId)
  const { data: catalog, refetch: refetchCatalog, error: catalogError } = useCatalogQuery(catalogId!, appInstanceId)
  const {
    data: apiCatalogInventory,
    refetch: refetchInventory,
    error: inventoryError,
  } = useCatalogInventoryQuery(catalogId!, appInstanceId)

  useWebsocket(
    () => Subscription.createForCatalog(pusherWebsocket, appInstanceId),
    () => Promise.all([refetchCatalogSummaries(), refetchCatalog()]),
  )
  useWebsocket(
    () => Subscription.createForInventory(pusherWebsocket, appInstanceId),
    () => refetchInventory(),
  )

  React.useEffect(() => {
    if (catalogError || inventoryError) {
      navigate(inventoryLoaderPath())
    }
  }, [catalogError, inventoryError, inventoryLoaderPath, navigate])

  if (!catalogSummaries || !catalog || !apiCatalogInventory) {
    return (
      <Layout>
        <Loading />
      </Layout>
    )
  }

  const catalogInventory = importCatalogInventory(apiCatalogInventory)
  return (
    <InventoryList
      catalogSummaries={catalogSummaries}
      catalog={catalog}
      catalogInventory={catalogInventory}
      selectCatalog={(catalogId) => navigate(inventoryPath(catalogId, section))}
      section={section}
    />
  )
}

export default Inventory
