import { ModalStyle as Modal } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { Grid, Main, Tab } from "./Styles"
import General from "./tabs/General"
import Inventory from "./tabs/Inventory"
import Notifications from "./tabs/Notifications"
import OrderStatus from "./tabs/OrderStatus"

export type SettingsTab = "general" | "notifications" | "order_status" | "inventory"

const SettingsModalContent = ({ close }: { close: () => void }): JSX.Element => {
  const { t } = useTranslation()

  const tabList: Array<{ tab: SettingsTab; title: string; element: JSX.Element }> = [
    { tab: "general", title: t(`popup.settings.tabs.general.tab_title`), element: <General /> },
    { tab: "order_status", title: t(`popup.settings.tabs.order_status.tab_title`), element: <OrderStatus /> },
    { tab: "notifications", title: t(`popup.settings.tabs.notifications.tab_title`), element: <Notifications /> },
    { tab: "inventory", title: t(`popup.settings.tabs.inventory.tab_title`), element: <Inventory /> },
  ]

  const [selectedTab, setSelectedTab] = React.useState<SettingsTab>("general")

  return (
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>{t(`popup.settings.title`)}</Modal.Title>
        <Modal.Close onClick={close} />
      </Modal.Header>

      <Grid>
        {tabList.map(({ tab, title }, index) => (
          <Tab onClick={() => setSelectedTab(tab)} $isActive={selectedTab === tab} key={index}>
            {title}
          </Tab>
        ))}
        <Main>{tabList.find(({ tab }) => tab === selectedTab)?.element}</Main>
      </Grid>
    </Modal.Content>
  )
}

export default SettingsModalContent
