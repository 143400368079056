import * as React from "react"
import { useTranslation } from "react-i18next"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Order } from "models/hubrise/Order"
import { toDateStringOl, toTimeStringOl } from "utils/dateHelpers"

import { Container, Info, Label, Value, ValueHighlight } from "./Styles"

const OtherInfo = ({ order }: { order: Order }): JSX.Element | null => {
  const { t } = useTranslation()
  const { colon, formatDateTime } = useTranslationFormatters()

  const paymentHTML = order.paymentSummaryHTML

  return (
    <Container>
      <Info>
        <Label>{t("popup.show_order.created_at") + colon}</Label>
        <Value>
          {toDateStringOl(order.createdAt)} {toTimeStringOl(order.createdAt)}
        </Value>
      </Info>

      <Info>
        <Label>{t("popup.show_order.expected_time") + colon}</Label>
        <Value>{formatDateTime(order.expectedTime, order.createdAt)}</Value>
      </Info>

      <Info>
        <Label>{t("popup.show_order.confirmed_time") + colon}</Label>
        <Value>{formatDateTime(order.confirmedTime, order.expectedTime)}</Value>
      </Info>

      {order.serviceType && (
        <Info>
          <Label>{t("popup.show_order.type_title") + colon}</Label>
          <ValueHighlight>{t(`popup.show_order.type.${order.serviceType}`)}</ValueHighlight>
        </Info>
      )}

      {paymentHTML !== null && (
        <Info>
          <Label>{t("popup.show_order.payment") + colon}</Label>
          <ValueHighlight dangerouslySetInnerHTML={{ __html: paymentHTML }} />
        </Info>
      )}

      {order.pointsDelta !== 0 && (
        <React.Fragment>
          <Info>
            <Label>{t("popup.show_order.points_earned") + colon}</Label>
            <ValueHighlight>{t("popup.show_order.loyalty_points", { count: order.pointsDelta })}</ValueHighlight>
          </Info>
          <Info>
            <Label>{t("popup.show_order.loyalty_new_balance") + colon}</Label>
            <ValueHighlight>{t("popup.show_order.loyalty_points", { count: order.pointsBalance })}</ValueHighlight>
          </Info>
        </React.Fragment>
      )}

      {order.customer.deliveryNotes !== null && (
        <Info>
          <Label>{t("popup.show_order.delivery_notes") + colon}</Label>
          <Value>{order.customer.deliveryNotes}</Value>
        </Info>
      )}

      {order.customerNotes !== null && (
        <Info>
          <Label>{t("popup.show_order.customer_notes") + colon}</Label>
          <Value>{order.customerNotes}</Value>
        </Info>
      )}

      {order.sellerNotes !== null && (
        <Info>
          <Label>{t("popup.show_order.seller_notes") + colon}</Label>
          <Value>{order.sellerNotes}</Value>
        </Info>
      )}
    </Container>
  )
}

export default OtherInfo
