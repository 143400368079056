export const Statuses = [
  "new",
  "received",
  "accepted",
  "in_preparation",
  "awaiting_shipment",
  "awaiting_collection",
  "in_delivery",
  "completed",
  "rejected",
  "cancelled",
  "delivery_failed",
] as const

export type Status = typeof Statuses[number]

export type ServiceType = "delivery" | "collection" | "eat_in"

export type Gender = "male" | "female"
