import { Spinner, colors, mixin, sizes } from "@hubrise/react-components"
import styled from "styled-components"

import { blockPadding } from "../consts"

export const StyledActions = styled.div`
  padding: ${blockPadding};
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  background-color: ${colors.backgroundLighter};
  border-left: 1px solid ${colors.borderLight};
`

export const Source = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const SourceLogo = styled.img`
  width: 3.5rem;
  height: 3.5rem;
`

export const SourceLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`

export const SourceLabel = styled.div`
  color: ${colors.textDark};
  font-weight: bold;
  text-transform: uppercase;
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const ButtonGroup = styled(Group)`
  gap: 0.75rem;
`

export const Title = styled.div`
  text-transform: uppercase;
  color: ${colors.textMedium};
`

export const Value = styled.div`
  color: ${colors.textDark};
  font-weight: bold;
  text-transform: uppercase;
`

export const Button = styled.div`
  ${mixin.centerElement};
  height: 2.5rem;
  padding: 0 0.75rem;

  color: ${colors.white};
  background-color: ${colors.primary};

  text-transform: uppercase;
  white-space: nowrap;
  word-break: keep-all;

  border: 1px solid transparent;
  border-radius: ${sizes.borderRadius};

  ${mixin.clickable};
`

export const PrintButton = styled(Button)`
  position: relative;
`

export const StyledSpinner = styled(Spinner).attrs({
  color: colors.white,
  secondaryColor: "rgba(0,0,0,0.05)",
  size: "1.5rem",
  thickness: 200,
})`
  position: absolute;
  right: 1rem;
`
