import { Money } from "@hubrise/react-components"

import {
  ImportedCharge,
  ImportedCustomer,
  ImportedDiscount,
  ImportedItem,
  ImportedLoyaltyOperation,
  ImportedOption,
  ImportedOrder,
  ImportedPayment,
} from "models/ImportedOrder"
import { Order, OrderId } from "models/hubrise/Order"
import { Charge } from "models/hubrise/order/Charge"
import { Customer } from "models/hubrise/order/Customer"
import { DealLine } from "models/hubrise/order/DealLine"
import { Discount } from "models/hubrise/order/Discount"
import { Item } from "models/hubrise/order/Item"
import { LoyaltyOperation } from "models/hubrise/order/LoyaltyOperation"
import { Option } from "models/hubrise/order/Option"
import { Payment } from "models/hubrise/order/Payment"
import { Gender, ServiceType, Status } from "models/hubrise/types"

import { parseDate, parseDecimal } from "./importOrder/helpers"

const importOrder = (importedOrder: ImportedOrder): Order => {
  const o = importedOrder

  return new Order(
    OrderId(o.id),
    o.status as Status,
    o.service_type as ServiceType | null,
    o.service_type_ref,
    parseDate(o.created_at),
    o.created_by,
    o.channel,
    o.connection_name,
    parseDate(o.expected_time),
    parseDate(o.confirmed_time),
    importCustomer(o.customer),
    o.customer_notes,
    o.seller_notes,
    o.coupon_codes,
    o.collection_code,
    Money.fromString(o.total),
    o.items.map(importItem),
    o.loyalty_operations.map(importLoyaltyOperation),
    o.charges.map(importCharge),
    o.payments.map(importPayment),
    o.discounts.map(importDiscount),
    o.deals,
  )
}

const importCustomer = (importedCustomer: ImportedCustomer): Customer => {
  const c = importedCustomer

  return new Customer(
    c.email,
    c.first_name,
    c.last_name,
    c.gender as Gender | null,
    parseDate(c.birth_date),
    c.company_name,
    c.phone,
    c.phone_access_code,
    c.address_1,
    c.address_2,
    c.postal_code,
    c.city,
    c.state,
    c.country,
    parseDecimal(c.latitude),
    parseDecimal(c.longitude),
    c.delivery_notes,
    c.sms_marketing,
    c.email_marketing,
  )
}

const importItem = (importedItem: ImportedItem): Item => {
  const i = importedItem

  return new Item(
    i.product_name,
    i.sku_name,
    i.sku_ref,
    Money.fromString(i.price),
    Number(i.quantity),
    Money.fromString(i.subtotal),
    parseDecimal(i.tax_rate),
    i.customer_notes,
    parseDecimal(i.points_earned, { nullIfZero: true }),
    parseDecimal(i.points_used, { nullIfZero: true }),
    i.options.map(importOption),
    i.deleted,
    i.deal_line ? new DealLine(i.deal_line.deal_key, i.deal_line.label) : null,
  )
}

const importOption = (importedOption: ImportedOption): Option => {
  const o = importedOption

  return new Option(o.option_list_name, o.name, o.ref, Money.fromString(o.price), o.quantity, o.removed ?? false)
}

const importLoyaltyOperation = (importedLoyaltyOperation: ImportedLoyaltyOperation): LoyaltyOperation => {
  const l = importedLoyaltyOperation

  return new LoyaltyOperation(l.reason, parseDecimal(l.delta), parseDecimal(l.new_balance))
}

const importCharge = (importedCharge: ImportedCharge): Charge => {
  const c = importedCharge

  return new Charge(c.name, c.ref, Money.fromString(c.price), parseDecimal(c.tax_rate), c.deleted)
}

const importPayment = (importedPayment: ImportedPayment): Payment => {
  const p = importedPayment

  return new Payment(p.name, p.ref, Money.fromString(p.amount), p.info, p.deleted)
}

const importDiscount = (importedDiscount: ImportedDiscount): Discount => {
  const d = importedDiscount

  return new Discount(d.name, d.ref, Money.fromString(d.price_off), d.deleted)
}

export default importOrder
